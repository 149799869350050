<template>
    <v-container class="ma-0 pa-0">
        <div v-if="showTrimmings" class="text-center text-h5">
            <!-- <div><v-icon x-large left>mdi-cached</v-icon></div>
            <div>Order Lifecycle</div> -->
            <div><v-icon x-large left>mdi-pipe</v-icon></div>
            <div>{{ title }}</div>
        </div>
        <v-container class="overflow-x-auto overflow-y-hide flex-nowrap d-flex">
            <v-spacer />
            
            <v-btn x-small outlined :class="highlightApprove ? highlightClass : null">Approve</v-btn>
                
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-btn x-small outlined :class="highlightRoute ? highlightClass : null">Plan Consignment Route</v-btn>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-btn x-small outlined :class="highlightMovement ? highlightClass : null">Create Movements</v-btn>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-btn x-small outlined :class="highlightAssign ? highlightClass : null">Assign To Journey</v-btn>
            
            <v-spacer />
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: 'BT-Courier-Cycle-Header',
    props: {
        highlightApprove: {
            type: Boolean,
            default: false
        },
        highlightClass: {
            type: String,
            default: 'success--text'
        },
        highlightRoute: {
            type: Boolean,
            default: false
        },
        highlightMovement: {
            type: Boolean,
            default: false
        },
        highlightAssign: {
            type: Boolean,
            default: false
        },
        showTrimmings: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Courier Order Pipeline'
        }
    }
}
</script>